import {IonIcon} from "react-ion-icon";
import Uploady, {useItemProgressListener, useBatchFinishListener, useBatchStartListener} from "@rpldy/uploady";
import UploadButton from "@rpldy/upload-button";
import React, {useState} from "react";
import config from "../config/config.json";
import { Line, Circle } from "rc-progress";

const filterBySize = (file) => {
    //filter out images larger than 5MB
    return file.size <= 5242880;
};

const ActionAddPhoto = (props) => {

    const setRefresh = props.setRefresh
    const [className, setClassName] = useState("none")


    const BatchFinish = () => {
        useBatchFinishListener((batch) => {
            console.log(`batch ${batch.id} finished uploading`);
            setClassName("none")
            setRefresh(Date.now)
        });
    };

    const BatchStart = () => {
        useBatchStartListener((batch) => {
            console.log(`batch ${batch.id} finished uploading`);
            setClassName("block")
        });
    };

    const UploadProgress = () => {

        const [uploads, setUploads] = React.useState({});
        const progressData = useItemProgressListener();

        if (progressData && progressData.completed) {
            const upload = uploads[progressData.id] ||
                { name: progressData.url || progressData.file.name, progress: [0] };

            if (!~upload.progress.indexOf(progressData.completed)) {
                upload.progress.push(progressData.completed);

                setUploads({
                    ...uploads,
                    [progressData.id]: upload,
                });
            }
        }

        const entries = Object.entries(uploads);
        //debugger
        //setClassName("none")
        //setClassName("block")
        if (entries.length > 0) {
            //setClassName("block")
        }

        console.log(entries)

        return (

            <div className={className}>
                <div className="progress">
                    <h2>Upload progress</h2>
                    <div className="progressInner">
                        {entries
                            .map(([id, { progress, name }]) => {
                                const lastProgress = progress[progress.length - 1];

                                return <div className="progressBar" key={id}>
                                    <p>{name}</p>
                                    <Line strokeWidth={2}
                                            strokeColor={lastProgress === 100 ? "#FF8C00FF" : "#e5ec1f"}
                                            percent={lastProgress} />
                                </div>;
                            })}
                    </div>
                </div>
            </div>

        );


    };

    return (
        <>

            <Uploady

                destination={{
                    url: config.API_URL + 'image/',
                    params: {"test": "lalal"},
                    headers: {"X-API-KEY": sessionStorage.getItem("apiKEY")} }}
                fileFilter={filterBySize}
                accept="image/*"
            >

                <UploadButton className="action">
                    <span className="icon">
                        <IonIcon name="add-circle-outline"></IonIcon>
                    </span>
                </UploadButton>

                <BatchStart />
                <UploadProgress/>
                <BatchFinish />

            </Uploady>

        </>
    )

}

export default ActionAddPhoto