
import React, {useState} from "react";
import {Button, ThemeProvider} from "@mui/material";
import MuiTheme from "../themes/MuiTheme";
import TextField from "@mui/material/TextField";
import APICall from "../tools/APICall";
import config from "../config/config.json";
import useFetch2 from "../tools/UseFetch2";
import useAPICall from "../tools/UseAPICall";
import APICall2 from "../tools/APICall2";

const ChatWrite = (prop) => {

    const counter = prop.counter
    const refresh = prop.refresh

    const [text, setText] = useState("")

    const sendMessage = () => {
        console.log(text)

        const postdata = {
            "counter": counter,
            "Message": text
        }

        fetch(config.API_URL + 'chat/send', {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "x-api-key": sessionStorage.getItem("apiKEY"),
                "Accept": '*/*',
            },
            body: JSON.stringify(postdata)
        }).then(r => {
            //console.log(method + ' ' + url)
            console.log(r)
            refresh()
        }).then(data => {
            console.log(data)
        }).catch(err => {
            //debugger
            if (err.name === 'AbortError') { // abychom zase nemenili state pokud uz jsme odesli ze stanky
                console.log(err.message)
            }
            else {
                console.log(err.message)
            }
        })

        //APICall2('POST', config.API_URL + 'chat/send', postdata)
        setText("")

        //refresh()

    }



    return(
        <>

            <div className="chat-write-outer">

                <ThemeProvider theme={MuiTheme}>
                    <TextField
                        type=""
                        label=""
                        value={text}
                        size="small"
                        onChange={(e) => setText(e.target.value)}
                        sx={{
                            width:"100%",
                            color: "#8f8f8f",
                            fontSize: "10px"
                        }}
                        inputProps={{style: {
                            fontSize: "0.9em",
                            lineHeight:"18px"
                            }}}
                        variant="outlined"
                        multiline
                        //maxRows={4}
                        rows={3}
                    />


                    <Button
                        variant="outlined"
                        size="small"
                        sx={{
                            width:"20px",
                            color: "white",
                            height: "72px"
                        }}
                        onClick={sendMessage}
                    >
                        <ion-icon name="send"></ion-icon>
                    </Button>

                </ThemeProvider>

                <div className="chat-write-info">
                    {text.length} char(s) written
                </div>

            </div>


        </>
    )
}

export default ChatWrite