import "../css/Events.css"

const Events = () => {


    //TODO: Vypis udalosti z DB

    return (
        <>

            <div className="events-page-outer">
                <div className="events-page">
                    <h2>Upcoming events near your location</h2>

                    <p>
                        Tired of online dating? Don't worry, so are we.
                        Choose from the upcoming events in your area below,
                        be there with a visible sign and wait to be approached by other DA members.
                    </p>

                    <div className="event-detail">
                        <span className="event-detail-subject">Prague DA night 2</span>
                        <div className="event-detail-where-when">
                            <span className="event-detail-where">Tretters bar Prague</span>
                            <span className="event-detail-when">12.11.2022 20:00</span>
                        </div>
                        <div className="event-detail-info">
                            This defines the default size of an element before the remaining space is
                            distributed. It can be a length (e.g. 20%, 5rem, etc.) or a keyword.
                            The auto keyword means “look at my width or height property”
                            (which was temporarily done by the main-size keyword until deprecated).
                            The content keyword means “size it based on the item’s content”
                        </div>

                        <div className="event-detail-sign">
                            and remember - as a sign wear a visible neck belt
                        </div>

                    </div>

                    <div className="event-detail">
                        <span className="event-detail-subject">DA Christmass in Prague</span>
                        <div className="event-detail-where-when">
                            <span className="event-detail-where">James Dean Prague</span>
                            <span className="event-detail-when">22.12.2022 20:00</span>
                        </div>
                        <div className="event-detail-info">
                            This defines the default size of an element before the remaining space is
                            distributed. It can be a length (e.g. 20%, 5rem, etc.) or a keyword.
                            The auto keyword means “look at my width or height property”
                            (which was temporarily done by the main-size keyword until deprecated).
                            The content keyword means “size it based on the item’s content”
                        </div>

                        <div className="event-detail-sign">
                            Wear X tattoo on your cheek
                        </div>

                    </div>

                    <p>
                        Come back later to see new events added
                    </p>

                </div>
            </div>

        </>
    )
}

export default Events