import "../css/UserProfile.css"
import React, {useState} from "react";
import PhotoCarousel from "./PhotoCarousel";
import ChckBox from "./ChckBox";
import config from "../config/config.json";

const UserProfile = (props) => {

    const userID = props.userID
    const [isPrivate, setIsPrivate] = useState(false)

    const UpdateStatus = (value) => {
        setIsPrivate(value)

        // let imageData = {
        //     Status: (value ? 0 : 1)
        // };

        // PUTData(config.API_URL + 'image/' + img, imageData)
    }

    return (
        <>

            <div className="userProfile">

                <PhotoCarousel userID={userID} />

                <div className="settings">

                    <h2>Vladimir</h2>
                    <p className="settingsText">Tell us something about you ... make yourself more attractive</p>

                    <div className="userProfileInfo">
                    <ChckBox
                        caption="Show private images"
                        checked={isPrivate}
                        onChange={UpdateStatus}
                    />
                    </div>

                </div>

            </div>

        </>
    )
}

export default UserProfile