import "../css/Login.css"
import TxtBox from "../components/TxtBox";
import React, {useState} from "react";
import Btn from "../components/Btn";
import config from "../config/config.json";
import {Button, ThemeProvider} from "@mui/material";
import MuiTheme from "../themes/MuiTheme";
import Code from "../register/Code";

import { US, CZ, ES, UA, DE, IT, HU, PL } from 'country-flag-icons/react/3x2'
import {useTranslation} from "react-i18next";
import i18n from "i18next";


const Login = (props) => {

    const { t } = useTranslation();

    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    const [error, setError] = useState("")

    const [register, setRegister] = useState(false)

    const setUserAPiKey = props.setUserApiKey

    const POSTData = (url, data) => {

        fetch( url, {
            method: 'POST',
            headers: {"content-type": "application/json"},
            body: JSON.stringify(data)
        }).then(r => {
            //debugger
            console.log('ted')
            console.log(r)
            return r.json()
        }).then(data => {
            //debugger
            console.log('ted jdou data')
            console.log(data)
            sessionStorage.setItem("apiKEY", data.ApiKEY)
            setUserAPiKey(sessionStorage.getItem("apiKEY"))
            setError(data.Error)
            if (data.err === undefined) {

            }
            else {

            }
        })
    }

    const login = (e) => {

        //debugger
        e.preventDefault()

        let loginData = {
            uname: email,
            pass: pass
        };

        setError("")

        POSTData(config.API_URL + 'user/login', loginData)
    }

    const doRegister = () => {
        setRegister(true)
    }

    const cancelRegister = () => {
        setRegister(false)
    }

    function changeLanguage(lng) {
        console.log(lng);
        console.log(i18n.languages)
        i18n.changeLanguage(lng);
    }

    return(
        <>

            {!register &&
                <div className="login-page">

                    <form onSubmit={login}>
                        <div className="login">

                                <div className="section">

                                    <h2>
                                        <img src={config.IMG_URL + "logo_small2.png"} className="login-logo" alt="logo"/>
                                        {t('member login')}
                                    </h2>

                                    <p className="sectionText">
                                        {t('login info')}
                                    </p>

                                    <span className="login-space-1"></span>

                                    <TxtBox label={t('email')} value={email} handleChange={setEmail} desc=""/>
                                    <TxtBox type="password" label={t('password')} value={pass} handleChange={setPass} desc=""/>

                                    <span className="login-space-2"></span>

                                    <Btn onClick={login} />

                                    <div className="flags">
                                        <US title="United States" className="flag" onClick={() => {changeLanguage("en")}} />
                                        <CZ title="United States" className="flag" onClick={() => {changeLanguage("cs")}} />
                                        <ES title="United States" className="flag" onClick={() => {changeLanguage("ES")}} />
                                        <UA title="United States" className="flag" onClick={() => {changeLanguage("UA")}} />
                                        <DE title="United States" className="flag" onClick={() => {changeLanguage("DE")}} />
                                        <IT title="United States" className="flag" onClick={() => {changeLanguage("IT")}} />
                                        <HU title="United States" className="flag" onClick={() => {changeLanguage("HU")}} />
                                        <PL title="United States" className="flag" onClick={() => {changeLanguage("PL")}} />
                                    </div>

                                </div>

                            {error && <div className="loginError">{error}</div>}



                        </div>


                    </form>

                    <div className="login-invite">
                        <div className="invite-link">{t('invited?')}</div>
                        <ThemeProvider theme={MuiTheme}>
                            <Button variant="outlined" onClick={doRegister}>{t('register')}</Button>
                        </ThemeProvider>
                    </div>

                </div>
            }

            {register &&
                <div className="login-page">

                        <Code cancelRegister={cancelRegister} />

                </div>
            }

        </>
    )
}

export default Login