import "../css/Btn.css"
import {Button, ThemeProvider} from "@mui/material";
import MuiTheme from "../themes/MuiTheme";


const Btn = (props) => {

    const text = props.text
    const onClick = props.onClick
    const className = props.className

    return (
      <>
          <ThemeProvider theme={MuiTheme}>
              <Button variant="outlined" onClick={onClick} >log-in</Button>
          </ThemeProvider>

      </>
    )

}

export default Btn