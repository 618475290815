import config from "../config/config.json";
import React, {useEffect, useState} from "react";
import ChckBox from "./ChckBox";
import UserProfile from "./UserProfile";
import useFetch from "../tools/UseFetch";
import ChatInGrid from "./ChatInGrid";
import Waiting from "./Waiting";
import MessageInGrid from "./MessageInGrid";
import ChatWrite from "./ChatWrite";
import {getDate} from "date-fns";

const ChatDetail = (props) => {

    const setCustomCaption = props.setCustomCaption
    const setCustomAction = props.setCustomAction
    const showUserProfile = props.showUserProfile

    const [refreshnum, setRefreshnum] = useState(Date.now)

    const counter = props.chat

    //TODO: Nacitani chatu
    const {data:messages, isPending, error} = useFetch(config.API_URL + 'chat/get-chat/' + counter, refreshnum)

    const chat = props.chat


    const refresh = () => {
        setRefreshnum(Date.now)
    }

    useEffect(() => {
        setCustomCaption("Vladimir")
        setCustomAction("userDetail")
    }, [])

    return(
        <>

            {isPending && <Waiting />}

            {error && <div className="loginError">{error}</div>}

            {showUserProfile && <UserProfile userID={counter} />}

            {!showUserProfile &&

                <div className="chat-content">

                    {messages &&
                        <>
                            <div className="message-outer">
                                {messages.map(message => (
                                    <MessageInGrid
                                        key={message.ID}
                                        id={message.ID}
                                        fromNick={message.FromNick}
                                        messageDate={message.MessageDate}
                                        counterNick=""
                                        date=""
                                        text={message.MessageText}
                                        direction={message.MessageDirection}
                                    />
                                ))}
                            </div>

                            <ChatWrite counter={counter} refresh={refresh} />

                        </>
                    }

                </div>

            }

        </>
    )
}

export default ChatDetail

//todo socket.io https://www.youtube.com/watch?v=HggSXt1Hzfk&list=PLj-4DlPRT48lXaz5YLvbLC38m25W9Kmqy&index=4