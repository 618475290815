import config from "../config/config.json";
import React from "react";

const ChatInGrid = (props) => {

    const selectChat = props.selectChat
    const ID = props.id
    const CounterID = props.counterID
    const CounterNick = props.counterNick
    const CounterPhoto = props.counterPhoto
    const MessageText = props.text
    const MessageDate = props.date
    const direction = props.direction

    return(
        <>

            <div className="chatInGrid" onClick={() => {selectChat(CounterID)}}>

                <span className="chatPhoto">
                    {CounterPhoto!=="" && <img src={config.UIMG_URL + CounterPhoto} alt=""/>}
                </span>

                <span className="chatPreview">
                    <h2>
                        {CounterNick}
                        {direction === 0 && <span className="chatPreview-icon"><ion-icon name="arrow-forward-outline"></ion-icon></span>}
                        {direction === 1 && <span className="chatPreview-icon"><ion-icon name="arrow-back-outline"></ion-icon></span>}
                    </h2>

                    <div className="chatPreviewText">{MessageText}</div>
                </span>
                <div className="chatDate">
                    {MessageDate}
                </div>
            </div>

        </>
    )
}

export default ChatInGrid