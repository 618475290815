import useFetch from "../tools/UseFetch";
import config from "../config/config.json";
import React from "react";

const UserInfo = (props) => {

    const invitationCode = props.invitationCode
    const {data:user, error, isPending} = useFetch(config.API_URL + "user/from-invite/" + invitationCode)

    //console.log("user", user)

    return (

        <div className="userinfo-outer">
            {isPending && <div>loading</div>}
            {user &&
                <div className="userinfo-card">
                    <div className="userinfo-imgBx">
                        {user.Photo !== "" && <img src={config.UIMG_URL + user.Photo} alt=""/>}
                    </div>
                    <div className="userinfo-content">
                        <div className="userinfo-details">
                            <h2 className="center">{user.Nick}<br />
                                <span>{user.From}</span><br />
                                {/*<span><b>platinum VIP member</b></span>*/}
                            </h2>
                        </div>
                    </div>
                </div>}
            {error && <div>{ error }</div>}
        </div>
    )
}

export default UserInfo