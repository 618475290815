import "../css/Chat.css"
import ChatInGrid from "../components/ChatInGrid";
import TxtBox from "../components/TxtBox";
import React, {useEffect, useState} from "react";
import PhotoDetail from "../components/PhotoDetail";
import ChatDetail from "../components/ChatDetail";
import useFetch from "../tools/UseFetch";
import config from "../config/config.json";
import PhotoInGrid from "../components/PhotoInGrid";
import Waiting from "../components/Waiting";

const Chats = (props) => {

    const refresh = props.refresh
    const setCustomCaption = props.setCustomCaption
    const setCustomAction = props.setCustomAction
    const showUserProfile = props.showUserProfile

    const [selectedChat, setSelectedChat] = useState(null)

    const [search, setSearch] = useState("")
    //const chats = true

    const { data:chats, isPending, error} = useFetch(config.API_URL + 'chat/get-list-own', refresh)


    useEffect(() => {
        setSelectedChat(null)
    }, [refresh])

    return (
        <>
            <div className="chats-outer">

                {isPending && <Waiting />}

                {error && <div className="loginError">{error}</div>}

                {chats && !selectedChat && <>
                    {/*<div className="chatSearch">*/}
                    {/*    <TxtBox label="search in chats" value={search} handleChange={setSearch} desc=""/>*/}
                    {/*</div>*/}

                    <div className="chats">

                        {!chats && <>

                                <div className="noChats">
                                    <h2>Nothing's here</h2>
                                    <p>
                                        For now... But it will get better soon.
                                        In the meantime, you can upload some attractive photos (the more the better)
                                        and make sure that your profile is not private and the visibility
                                        is set correctly in the settings.
                                    </p>

                                    <p>... and come back soon</p>
                                    <span className="bckSymbol">:(</span>

                                </div>

                            </>}

                        {chats && !selectedChat &&
                            chats.map(chat => (
                                <ChatInGrid
                                    key={chat.ID}
                                    id={chat.ID}
                                    selectChat={setSelectedChat}
                                    counterID={chat.CounterID}
                                    counterPhoto={chat.CounterPhoto}
                                    counterNick={chat.CounterNick}
                                    date={chat.MessageDate}
                                    text={chat.MessageText}
                                    direction={chat.MessageDirection}
                                />
                            ))
                          }

                    </div>
                </>}

                {selectedChat && <ChatDetail
                    chat={selectedChat}
                    setCustomCaption={setCustomCaption}
                    setCustomAction={setCustomAction}
                    showUserProfile={showUserProfile}
                />}

            </div>

        </>
    )
}

export default Chats