const MessageInGrid = (props) => {

    const Text = props.text
    const FromNick = props.fromNick
    const MessageDate = props.messageDate
    const Direction = props.direction

    return(
        <>

            {Direction === 0 &&
                <div className="message">
                    <div className="message-inner">
                        <div className="message-header">
                            <div className="message-nick">{FromNick}</div>
                            <div className="message-date">{MessageDate}</div>
                        </div>
                        <div className="message-body">{Text}</div>

                    </div>
                </div>
            }

            {Direction === 1 &&
                <div className="message-right">
                    <div className="message-inner-right">
                        <div className="message-header-right">
                            <div className="message-nick">{FromNick}</div>
                            <div className="message-date">{MessageDate}</div>
                        </div>
                        <div className="message-body">{Text}</div>

                    </div>
                </div>
            }

        </>
    )
}

export default MessageInGrid
