import "../css/Header.css"
import config from "../config/config.json";
import ActionAddPhoto from "./ActionAddPhoto";
import ActionUserDetail from "./ActionUserDetail";

const Header = (props) => {

    const caption = props.caption
    const action = props.action
    const setRefresh = props.setRetresh
    const togleShowUserProfile = props.togleShowUserProfile

    return (
        <div className="header">

            <img src={config.IMG_URL + "logo_small2.png"} className="logo"  alt="logo"/>

            <h2>{caption}</h2>

            <div className="action">

                {(action==="addPhoto") && <ActionAddPhoto setRefresh={setRefresh} />}
                {(action==="userDetail") && <ActionUserDetail setRefresh={setRefresh} togleShowUserProfile={togleShowUserProfile}/>}

            </div>

        </div>


    )
}

export default Header