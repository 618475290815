import { createTheme } from '@mui/material/styles';


const MuiTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: '#ff8c00',
            dark: '#ff8c00',
        },
        secondary: {
            main: '#f50057',
        },
        text: {
            primary: '#ffffff',
            secondary: 'rgba(255,255,255,0.5)',
            disabled: 'rgba(255,255,255,0.5)',
            hint: 'rgba(255,255,255,0.5)',
        },
    },

});

export default MuiTheme