import config from "../config/config.json";
import {IonIcon} from "react-ion-icon";
import React, {useState} from "react";
import useFetch from "../tools/UseFetch";

const PhotoCarousel = (props) => {

    const refresh = props.refresh
    const userID = props.userID
    const { data:images, isPending, error} = useFetch(config.API_URL + 'image/get-user-images/' + userID, refresh)

    const [actualImage, setActualImage] = useState(0)

    //images && console.log(images[actualImage].Name)

    const goLeft = () => {
        if (actualImage > 0) setActualImage(actualImage - 1)
    }

    const goRight = () => {
        if (actualImage < images.length -1) setActualImage(actualImage + 1)
    }

    return(
        <>

            {images && images.length > 0 &&
                <div className="userImageDetail">
                    <img src={config.UIMG_URL + images[actualImage].Name} alt=""/>

                    {(actualImage > 0) && <div className="imageLeft" onClick={goLeft}>
                            <span className="imageLeftIcon">
                                {/*<IonIcon name="chevron-back"></IonIcon>*/}
                                &#60;
                            </span>
                    </div>}

                    {(actualImage < images.length -1) && <div className="imageRight" onClick={goRight}>
                            <span className="imageRightIcon">
                                {/*<IonIcon name="chevron-forward"></IonIcon>*/}
                                >
                            </span>
                    </div>}
                </div>
            }

            {images && images.length === 0 &&
                <div className="userImageDetail-noImage">
                    <div className="userImageDetail-noImage-text">user has no photo yet</div>
                </div>
            }

        </>
    )
}

export default PhotoCarousel