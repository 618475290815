import useFetch from "../tools/UseFetch";
import config from "../config/config.json";
import React, {useEffect, useState} from "react";
import {IonIcon} from "react-ion-icon";
import ChckBox from "./ChckBox";
import Waiting from "./Waiting";
import {Button, ThemeProvider} from "@mui/material";
import MuiTheme from "../themes/MuiTheme";
import APICall from "../tools/APICall";
import {useNavigate} from "react-router-dom";


const PUTData = (url, data) => {

    fetch( url, {
        method: 'PUT',
        headers: {"content-type": "application/json"},
        body: JSON.stringify(data)
    }).then(r => {
        console.log('ted')
        console.log(r)
        return r.json()
    }).then(data => {
        //debugger
        console.log(data.Err)
        if (data.err === undefined) {

        }
        else {

        }
    })
}


const PhotoDetail = (props) => {

    const img = props.selected
    const setCustomCaption = props.setCustomCaption
    const setCustomAction = props.setCustomAction
    const menuSelect = props.menuSelect

    const {data:image, isPending, error} = useFetch(config.API_URL + 'image/' + img, 0)

    const [isPrivate, setIsPrivate] = useState(false)
    const [isProfile, setIsProfile] = useState(false)



    useEffect(() => {
        if (image !== null) {
            setIsPrivate(image.Status === 0)
            setIsProfile(image.Profile ===1)
        }
    }, [image])


    useEffect(() => {
        setCustomCaption("Photo")
        setCustomAction("none")
        //navigate("/4")
    }, [])

    const UpdateStatus = (value) => {
        setIsPrivate(value)

        let imageData = {
            Status: (value ? 0 : 1)
        };

        PUTData(config.API_URL + 'image/' + img, imageData)
    }


    const SetProfilePhoto = () => {
        setIsProfile(true)
        APICall('PUT', config.API_URL + 'image/set-profile/' + img, '')
    }

    const DeleteImage = () => {
 //       if (window.confirm("Delete this image? Are you sure?") === true) {
            APICall('DELETE', config.API_URL + 'image/' + img, '')
            menuSelect(4)
//        }

    }




    return (
        <>

            {isPending && <Waiting />}

            {image &&
                <>

                    <div className="imageDetail">
                        <img src={config.UIMG_URL + img}  alt=""/>
                        {isPrivate && <span className="icon"><IonIcon name="eye-off"></IonIcon></span>}
                    </div>


                    <div className="imageInfo">

                        {!isProfile && <>
                            <ChckBox
                                caption="Private image"
                                text="Private images are only visible to you and the users you have authorised."
                                checked={isPrivate}
                                onChange={UpdateStatus}
                            />

                            <span className="Photo-deleteButton">
                                <ThemeProvider theme={MuiTheme}>
                                    <Button variant="outlined" onClick={SetProfilePhoto}>Set as profile photo</Button>
                                </ThemeProvider>
                                <p>
                                    Profile photo can not be private or deleted.
                                </p>
                            </span>
                        </>}

                        {isProfile && <>

                            <span className="Photo-privatePhoto-info">
                                <p>
                                    This is your profile picture so it can not be set as private.
                                </p>
                                <p>
                                    If you delete this picture, you should select another one as a profile
                                </p>
                            </span>
                        </>}

                        <span className="Photo-deleteButton">
                            <ThemeProvider theme={MuiTheme}>
                                <Button variant="outlined" color="error" onClick={DeleteImage}>Delete image</Button>
                            </ThemeProvider>
                            <p>
                                Be careful - the image will be deleted permanently
                            </p>
                        </span>

                    </div>



                </>}
        </>
    )
}

export default PhotoDetail