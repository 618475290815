import './App.css';
import {BrowserRouter as Router, Navigate, redirect, Route, Routes} from "react-router-dom";
import Main from "./pages/Main";
import Menu from "./components/Menu";



function App() {

    const title = 'welcome to DA';


    return (

        <>
            {/*<Menu active={1} />*/}
            <Router>

                <div className="App">

                    <div className="content">

                        <Routes>
                            <Route path="/" element={<Navigate to="/1" />} />
                            <Route path="/:MID" element={<Main />} />
                            {/*<Route path="/profile" element={<MainProfile />} />*/}

                        </Routes>

                    </div>
                </div>

            </Router>

        </>
    );
}

export default App;
