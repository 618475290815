import "../css/Settings.css"
import Range from "../components/Range";
import ChckBox from "../components/ChckBox";
import React, {useEffect, useState} from "react";
import TxtBox from "../components/TxtBox";
import useFetch from "../tools/UseFetch";
import config from "../config/config.json";
import Waiting from "../components/Waiting";
import {Button, ThemeProvider} from "@mui/material";
import MuiTheme from "../themes/MuiTheme";
import APICall from "../tools/APICall";
import Btn from "../components/Btn";
import UseAPICall from "../tools/UseAPICall";
import APICall2 from "../tools/APICall2";

const Settings = (props) => {

    const refresh = props.refresh
    const setCustomCaption = props.setCustomCaption
    const setCustomAction = props.setCustomAction

    const [privateProfile, setPrivateProfile] = useState(false)
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [facebook, setFacebook] = useState("")
    const [instagram, setInstagram] = useState("")
    const [contactSave, setContactSave] = useState(false)

    const [city, setCity] = useState("")
    const [nick, setNick] = useState("")
    const [name, setName] = useState("")
    const [birthDate, setBirthDate] = useState("")
    const [basicSave, setBasicSave] = useState(false)

    const [refreshNum, setRefreshNum] = useState(Date.now)

    const [viewChangePass, SetViewChangePass] = useState(false)
    const [oldPass, setOldPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [newPassAgain, setNewPassAgain] = useState("")

    const [cpIsPending, setCPIsPending] = useState(false)
    const [cpErr, setCPErr] = useState("")

    //TODO: vytvorit settings pokud pro uzivatele neexistuje

    const { data:settings, isPending, error} = useFetch(config.API_URL + 'settings/get-own', refresh)
    const { data:userinfo, isPendingui, errorui} = useFetch(config.API_URL + 'settings/get-own-userinfo', refresh)

    const [age, setAge] = React.useState([20, 37]);
    const [income, setIncome] = React.useState([10000]);

    const [savingPass, setSavingPass] = useState(false)

    useEffect(() => {
        if(settings != null) {
            setAge([settings.AgeFrom, settings.AgeTo])
            setIncome(settings.MinimumIncome)
            setPrivateProfile(settings.Private === 1)
        }
    }, [settings])

    useEffect(() => {
        if(userinfo != null) {
            setPhone(userinfo.Phone)
            setEmail(userinfo.Email)
            setFacebook(userinfo.Facebook)
            setInstagram(userinfo.Instagram)

            setCity(userinfo.City)
            setNick(userinfo.Nick)
            setName(userinfo.Name)
            setBirthDate(userinfo.Birthdate)
        }
    }, [userinfo])

    useEffect(() => {
        if(userinfo != null) {
            let showSave = (email !== userinfo.Email || phone !== userinfo.Phone || facebook !== userinfo.Facebook || instagram !== userinfo.Instagram)
            setContactSave(showSave)
        }
    }, [email, facebook, instagram, phone, refreshNum])


    useEffect(() => {
        if(userinfo != null) {
            let showSave = (city !== userinfo.City || nick !== userinfo.Nick || name !== userinfo.Name)
            setBasicSave(showSave)
        }
    }, [city, name, nick, refreshNum])


    const updateAge = () => {
        console.log("Update age now")
        const postdata = {
            "AgeFrom": age[0],
            "AgeTo": age[1]
        }
        APICall('POST', config.API_URL + 'settings/update-age', postdata)
    }

    const updateIncome = () => {
        console.log("Update Income now")
        const postdata = {
            "MinimumIncome": income
        }
        APICall('POST', config.API_URL + 'settings/update-income', postdata)
    }

    const updateContactData = () => {
        console.log("Update Contact now")

        userinfo.Instagram = instagram
        userinfo.Facebook = facebook
        userinfo.Phone = phone
        userinfo.Email = email

        APICall('POST', config.API_URL + 'settings/update-own-userinfo', userinfo)

        setRefreshNum(Date.now)
    }

    const updateBasicData = () => {
        console.log("Update basic now")

        userinfo.City = city
        userinfo.Nick = nick
        userinfo.Name = name

        APICall('POST', config.API_URL + 'settings/update-own-basicinfo', userinfo)

        setRefreshNum(Date.now)
    }

    const UpdatePrivate = (value) => {
        setPrivateProfile(value)

        const postdata = {
            "Private": (value ? 1 : 0)
        }
        APICall('POST', config.API_URL + 'settings/update-private', postdata)
    }

    const ShowChangePassword = () => {
        console.log("Chnage pass")
        //setCustomCaption("Change pass")
        SetViewChangePass(true)
    }

    const HideChangePassword = () => {
        SetViewChangePass(false)
        setOldPass("")
        setNewPass("")
        setNewPassAgain("")
        setCPIsPending(false)
        setCPErr("")
    }

    const ChangePassword = () => {

        const postdata = {
            "OldPass": oldPass,
            "NewPass": newPass,
            "NewPassAgain": newPassAgain
        }

        setCPIsPending(true)
        setCPErr(null)

        //debugger
        fetch( config.API_URL + 'settings/change-own-pass', {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "x-api-key": sessionStorage.getItem("apiKEY"),
                "Accept": '*/*',
            },
            body: JSON.stringify(postdata)
        }).then(r => {
            console.log('ted')
            console.log(r)
            return r.json()
        }).then(data => {
            //debugger
            console.log(data.err)
            if (data.err === undefined) {
                setCPIsPending(false)
                HideChangePassword()
            }
            else {
                setCPIsPending(false)
                setCPErr(data.err)
            }
        })
    }



    return (
        <>

            {(isPending || isPendingui) && <Waiting />}

            {(error || errorui) && <div className="loginError">{error + errorui}</div>}

            {settings && userinfo && <div className="settings">


                <h2>Account</h2>
                <p className="settingsText">Management of your account and basic data</p>

                <div className="section">

                    <ChckBox
                        caption="Private profile"
                        text="Private profile is not visible to anyone but you"
                        checked={privateProfile}
                        onChange={UpdatePrivate}
                    />

                </div>

                <div className="section">

                    <h2>Basic info</h2>

                    <p className="sectionText">Basic info about you. Birthdate is read only.
                        If you wanna change your birthdate, contact support.</p>

                    <TxtBox label="City" value={city} handleChange={setCity} desc=""/>
                    <TxtBox label="Nick" value={nick} handleChange={setNick} desc=""/>
                    <TxtBox label="Name" value={name} handleChange={setName} desc=""/>
                    <TxtBox label="Birth date" value={birthDate} desc=""/>

                    {basicSave && <span className="settings-section-save">
                        <ThemeProvider theme={MuiTheme}>
                            <Button variant="outlined" onClick={updateBasicData}>Save changes</Button>
                        </ThemeProvider>
                    </span>}

                </div>

                <div className="section">

                    <h2>Contact</h2>

                    <p className="sectionText">Enter your contact details here. Phone and e-mail are always private</p>

                    <TxtBox label="phone" value={phone} handleChange={setPhone} desc=""/>
                    <TxtBox label="e-mail" value={email} handleChange={setEmail} desc=""/>
                    <TxtBox label="facebook" value={facebook} handleChange={setFacebook} desc=""/>
                    <TxtBox label="instagram" value={instagram} handleChange={setInstagram} desc=""/>

                    {contactSave && <span className="settings-section-save">
                        <ThemeProvider theme={MuiTheme}>
                            <Button variant="outlined" onClick={updateContactData}>Save changes</Button>
                        </ThemeProvider>
                    </span>}

                </div>

                <div className="section">
                    <h2>Change password</h2>
                    {!viewChangePass && <>
                        <p className="sectionText">Click here to change your password</p>
                        <ThemeProvider theme={MuiTheme}>
                            <Button variant="outlined" onClick={ShowChangePassword}>Change</Button>
                        </ThemeProvider>
                    </>}

                    {viewChangePass && <>

                        <p className="sectionText">Enter old and new password here</p>

                        <TxtBox type="Password" label="Old password" value={oldPass} handleChange={setOldPass} desc=""/>
                        <TxtBox type="Password" label="New Password" value={newPass} handleChange={setNewPass} desc=""/>
                        <TxtBox type="Password" label="New Password again" value={newPassAgain} handleChange={setNewPassAgain} desc=""/>

                        <ThemeProvider theme={MuiTheme}>
                            <Button variant="outlined" onClick={ChangePassword}>OK</Button>&nbsp;
                            <Button variant="outlined" onClick={HideChangePassword}>Cancel</Button>
                        </ThemeProvider>

                        {cpErr && <p className="error">{cpErr}</p>}

                    </>}

                </div>

                <h2>Visibility & privacy</h2>

                <p className="settingsText">Setting the visibility of your account -
                    select the attributes of the men who will see your profile.</p>

                <div className="section">
                    <ChckBox
                        caption="Private profile"
                        text="Private profile is not visible to anyone but you"
                        checked={privateProfile}
                        onChange={UpdatePrivate}
                    />
                </div>

                <div className="section">
                    <h2>Age range</h2>
                    <div className="range">
                        <Range value={age} setValue={setAge} min={18} max={90} commit={updateAge}/>
                    </div>
                    <span className="values">{age[0] + ' - ' + age[1]}</span>
                </div>

                <div className="section">
                    <h2>Minimum income</h2>
                    <div className="range">
                        <Range value={income} setValue={setIncome} min={0} max={50000} step={2000}
                               commit={updateIncome}
                               text="select the minimum monthly income that your dream man should have.
                               The lower the ammount the more men will see you"
                        />
                    </div>
                    <span className="values">{'$' + income.toLocaleString('en-EN')}</span>
                </div>

            </div>}

        </>
    )
}

export default Settings