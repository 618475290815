import config from "../config/config.json";
import TxtBox from "../components/TxtBox";
import React, {useState} from "react";
import {Button, ThemeProvider} from "@mui/material";
import MuiTheme from "../themes/MuiTheme";
import Reg from "./Reg";
import UserCreated from "./UserCreated";
import {useTranslation} from "react-i18next";

const Code = (props) => {

    const { t } = useTranslation();

    const cancelRegister = props.cancelRegister

    const [code, setCode] = useState("")

    const [codeOK, setCodeOK] = useState(false)
    const [codeWrong, setCodeWrong] = useState(false)
    const [userCreated, setUserCreated] = useState(false)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const useCode = () => {
        const url = config.API_URL + "reg/check-code/" + code;
        console.log(url)
//        setIsPending(true)
        fetch(url, {
            method: 'GET'
        }).then((res) => {
            console.log(res)
            return res.json()
        }).then(data => {
            console.log(data)
            if (data.codeOK === 'true') {
                setCodeOK(true)
                setCodeWrong(false)
            }
            else {
                setCodeOK(false)
                setCodeWrong(true)
            }

        })
    }

    return(
        <>

            {/*<div className="register-page">*/}
            <div className="login">
                <div className="section">

                {!codeOK && !userCreated && <>
                    <div className="register-space"></div>
                    <div className="register-header">
                        <img src={config.IMG_URL + "logo2.png"} className="register-header-logo" alt="logo"/>
                    </div>
                    <div className="register-space"/>
                    <div className="register-code-page">
                        <p>
                            {t('code info')}
                        </p>
                        <div className="register-space"></div>
                        <TxtBox label={t('code')} value={code} handleChange={setCode} desc=""/>
                    </div>

                    <div className="register-space"/>
                    <ThemeProvider theme={MuiTheme}>
                        <Button variant="outlined" color1="success" onClick={useCode}>{t('check code')}</Button>&nbsp;
                        <Button variant="outlined" color="error" onClick={cancelRegister}>{t('cancel')}</Button>
                    </ThemeProvider>

                    {codeWrong &&
                        <div className="register-wrong-code">Entered code is either invalid or already in use. Try
                            another one.</div>}

                </>}

                {codeOK && !userCreated && <Reg code={code} setEmail={setEmail} setUserCreated={setUserCreated} setPassword={setPassword}/>}
                {/*{codeOK && !userCreated && <>aaa</>}*/}

                {userCreated && <UserCreated userEmail={email} userPassword={password}/>}
                </div>

            </div>

        </>
    )
}

export default Code