import {IonIcon} from "react-ion-icon";

const MenuItem = (props) => {

    const active = props.active
    const icon = props.icon
    const text = props.text

    const setActive = props.setActive

    return (

        <li className={(active) ? 'list active' : 'list'} onClick={setActive}>
            <span>
                <span className="icon">
                    <IonIcon name={icon}></IonIcon>
                </span>
                <span className="text">{text}</span>
            </span>
        </li>

    )
}

export default MenuItem