import config from "../config/config.json";
import React from "react";
import {IonIcon} from "react-ion-icon";
import {useNavigate} from "react-router-dom";

const PhotoInGrid = (props) => {

    const img = props.img
    let isPrivate = !props.profile && props.private
    const isProfile = props.profile
    const selectPhoto = props.selectPhoto

    let navigate = useNavigate()

    const selectPhoto1 = (img) => {
        navigate("/40") //TODO - nejak lepe vymyslet vraceni po kliknuti na detail fotky nebo cehokoli
        selectPhoto(img)
    }

    return (
        <div className="photo" onClick={() => {selectPhoto1(img)}}>
            <img src={config.UIMG_URL + img}  alt=""/>
            {isPrivate && <span className="icon"><IonIcon name="eye-off"></IonIcon></span>}
            {isProfile && <span className="ProfileIcon"><IonIcon name="happy"></IonIcon></span>}
        </div>
    )
}

export default PhotoInGrid