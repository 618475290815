import "../css/Photos.css"
import React, {useEffect, useState} from "react";
import config from "../config/config.json";
import PhotoInGrid from "../components/PhotoInGrid";
import useFetch from "../tools/UseFetch";
import PhotoDetail from "../components/PhotoDetail";
import {IonIcon} from "react-ion-icon";
import Waiting from "../components/Waiting";


const Photos = (props) => {


    //debugger
    const refresh = props.refresh
    const setCustomCaption = props.setCustomCaption
    const setCustomAction = props.setCustomAction
    const menuSelect = props.menuSelect

    const [selectedPhoto, setSelectedPhoto] = useState(null)

    const { data:images, isPending, error} = useFetch(config.API_URL + 'image/get-own', refresh)

    console.log("photo component")
    console.log(refresh)

    //const isPendingTmp = true

    useEffect(() => {
        setSelectedPhoto(null)
    }, [refresh])

    return (

        <>

            <div className="gallery-outer">

                {isPending && <Waiting />}

                {error && <div className="loginError">{error}</div>}

                {images && !isPending && (images.length === 0) && <>

                    <div className="noImages-outer">

                        <div className="noImages">
                            <h2>No uploaded images yet</h2>
                            <p>
                                Upload new image(s) by clicking on the <IonIcon name="add-circle-outline"></IonIcon> icon in the top right corner.
                            </p>

                            <span className="bckSymbol"><ion-icon name="happy"></ion-icon></span>

                        </div>

                    </div>
                </>}

                {!selectedPhoto && <div className="gallery">

                    {!isPending && images && (images.length !== 0) && images.map(image => (
                        <PhotoInGrid key={image.ID} id={image.ID} img={image.Name} private={(image.Status === 0)}
                                     profile={(image.Profile === 1)}
                                     selectPhoto={setSelectedPhoto}/>
                    ))}

                </div>}

                {selectedPhoto && <PhotoDetail selected={selectedPhoto} setCustomCaption={setCustomCaption}
                                               setCustomAction={setCustomAction} menuSelect={menuSelect} />}

            </div>

        </>


    )
}

export default Photos