import React from "react";
import config from "../config/config.json";


const ActionUserDetail = (props) => {

    const togleShowUserProfile = props.togleShowUserProfile

    const handleClick = () => {
        togleShowUserProfile()
        console.log("user detail")
    }

    return(
        <>
            <div className="action" onClick={handleClick}>
                <img src={config.IMG_URL + "1.png"} alt="" className="actionImage"/>
            </div>
        </>
    )
}

export default ActionUserDetail