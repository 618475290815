import Menu from "../components/Menu";
import Header from "../components/Header";
import {useEffect, useState} from "react";
import Home from "../MainComponents/Home";
import Profile from "../MainComponents/Profile";
import Chats from "../MainComponents/Chats";
import Photos from "../MainComponents/Photos";
import Settings from "../MainComponents/Settings";
import Login from "../MainComponents/Login";
import {BrowserRouter, useNavigate, useParams} from "react-router-dom"
import menu from "../components/Menu";

const Main = () => {

    const [active, setActive] = useState(1)
    const [customCaption, setCustomCaption] = useState("")
    const [customAction, setCustomAction] = useState("")

    //const [userAPIKey, setUserAPIKey] = useState("")
    const [userAPIKey, setUserAPIKey] = useState(sessionStorage.getItem("apiKEY"))

    const [showUserProfile, setShowUserProfile] = useState(false) // pokud je jine nez 0 zobrazi ze v chatu profil uzivatele misto chatu

    const caption = ["", "Home", "Profile", "Chats", "Photos", "Settings"]
    const action = ["","","","","addPhoto",""]

    // pro zmenu caption a action z komponent a ne jen z menu
    const activeCaption = (customCaption === "") ? caption[active] : customCaption
    const activeAction = (customAction === "") ? action[active] : customAction

    const [refreshMain, setRefreshMain] = useState(Date.now)

    let navigate = useNavigate()

    const togleShowUserProfile = () => {
        setShowUserProfile(!showUserProfile)
    }

    let params = useParams()
    let paramsMenuID = parseInt(params["MID"], 10)

    //TODO - proc nejde na produkci refres???
    //Vyreseno - https://stackoverflow.com/questions/44038456/how-to-setup-apache-server-for-react-route

    useEffect(() => {
        console.log("Effect - " + paramsMenuID)
        if (paramsMenuID < 10) { //TODO - nejak lepe vymyslet vraceni po kliknuti na detail fotky nebo cehokoli
            menuSelect(paramsMenuID)}
    }, [paramsMenuID])

    const menuSelect = (menuID) => {

        //history.push("/")
        console.log("Params Menu ID = " + paramsMenuID)
        //debugger

        //console.log("UAPK")
        //console.log(userAPIKey)
        setCustomAction("")
        setCustomCaption("")
        setActive(menuID)
        setShowUserProfile(false)

        console.log(refreshMain)
        //let newRefresh = Math.floor(Math.random() * (100000))
        let newRefresh = Date.now
        setRefreshMain(newRefresh)

        if (menuID !== paramsMenuID) {
            navigate("/" + menuID)
        }

        // if (menuID === 2) {
        //     navigate("/profile")
        // }
        // else {
        //     navigate("/")
        // }

    }

    return (
        <>

            {!userAPIKey && <>

                <Login setUserApiKey={setUserAPIKey} />

            </>}

            {userAPIKey && <>
                <div className="mainContent">

                    {(active === 1) && <Home refresh={refreshMain} setCustomCaption={setCustomCaption}
                                             setCustomAction={setCustomAction} menuSelect={menuSelect} />}
                    {(active === 2) && <Profile refresh={refreshMain} setCustomCaption={setCustomCaption}
                                                setCustomAction={setCustomAction} menuSelect={menuSelect} />}
                    {(active === 3) && <Chats refresh={refreshMain} setCustomCaption={setCustomCaption}
                                              setCustomAction={setCustomAction} showUserProfile={showUserProfile}/>}
                    {(active === 4) && <Photos refresh={refreshMain} setCustomCaption={setCustomCaption}
                                               setCustomAction={setCustomAction} menuSelect={menuSelect} />}
                    {(active === 5) && <Settings refresh={refreshMain} setCustomCaption={setCustomCaption}
                                                 setCustomAction={setCustomAction}/>}

                </div>
                <Header
                    caption={activeCaption}
                    action={activeAction}
                    setRetresh={setRefreshMain}
                    togleShowUserProfile={togleShowUserProfile}
                />
                <Menu active={active} setActive={menuSelect}/>
            </>}

        </>

    )
}

export default Main