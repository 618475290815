const ContentBox = (props) => {

    const url = props.url

    const content = "<b>bla</b> - " + url

    return (
        <>

            <div dangerouslySetInnerHTML={{__html: content}}></div>

        </>
    )

}

export default ContentBox