import "../css/ChckBox.css"
import React from "react";

const ChckBox = (props) => {

    const caption = props.caption
    const text = props.text
    const checked = props.checked
    const onChange = props.onChange

    return (

        <div className="chckbox">

            <input type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)}   />
            <label onClick={(e) => onChange(!checked)} ></label>
            <span className={checked ? "checked" : "" } onClick={(e) => onChange(!checked)}>{caption}</span>
            <p>{text}</p>

        </div>

    )
}

export default ChckBox