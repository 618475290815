import "../css/Infopage.css"

const Hww = () => {
    return(
        <>


            <div className="info-page-outer">
                <div className="info-page">
                    <h2>How we works</h2>
                    <h3>We are different</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                        Duis condimentum augue id magna semper rutrum. Etiam bibendum elit eget erat.
                        Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                        Phasellus rhoncus. Etiam quis quam. Vivamus ac leo pretium faucibus. Sed vel lectus.
                        Donec odio tempus molestie, porttitor ut, iaculis quis, sem. Etiam dui sem, fermentum vitae,
                        sagittis id, malesuada in, quam. Vivamus luctus egestas leo.
                        Vestibulum erat nulla, ullamcorper nec, rutrum non, nonummy ac, erat.
                        Integer in sapien. Duis condimentum augue id magna semper rutrum.
                        Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                        per inceptos hymenaeos. Mauris dictum facilisis augue. Integer tempor.
                        Nulla pulvinar eleifend sem. Aliquam id dolor. Nunc dapibus tortor vel mi dapibus sollicitudin.
                    </p>

                    <p>
                        Temporibus autem quibusdam et aut officiis debitis aut rerum
                        necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
                        Praesent vitae arcu tempor neque lacinia pretium. Morbi imperdiet, mauris ac auctor dictum,
                        nisl ligula egestas nulla, et sollicitudin sem purus in lacus. Morbi leo mi,
                        nonummy eget tristique non, rhoncus non leo. Praesent in mauris eu tortor porttitor accumsan.
                        Quisque tincidunt scelerisque libero. Aenean fermentum risus id tortor.
                        Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                        Donec quis nibh at felis congue commodo. Aliquam erat volutpat. Integer imperdiet lectus quis justo.
                    </p>

                    <h3>Man's role</h3>
                    <p>
                        Fusce wisi. Vivamus porttitor turpis ac leo. Duis pulvinar. Duis ante orci, molestie vitae
                        vehicula venenatis, tincidunt ac pede. Duis risus. Vestibulum erat nulla, ullamcorper nec,
                        rutrum non, nonummy ac, erat. Integer in sapien. Nam quis nulla. Nulla non lectus sed nisl
                        molestie malesuada. Morbi leo mi, nonummy eget tristique non, rhoncus non leo.
                        Pellentesque arcu. Quisque tincidunt scelerisque libero. Nullam justo enim, consectetuer nec,
                        ullamcorper ac, vestibulum in, elit. Nunc tincidunt ante vitae massa. Maecenas libero.
                        Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla, et sollicitudin sem
                        purus in lacus. Aliquam erat volutpat. Fusce dui leo, imperdiet in, aliquam sit amet,
                        feugiat eu, orci.
                    </p>

                </div>
            </div>

        </>
    )
}

export default Hww