import "../css/Range.css"
import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {ThemeProvider} from "@mui/material";
import MuiTheme from "../themes/MuiTheme";

function valuetext(value) {
    return `${value}°C`;
}


const Range = (props) => {

    //const [value, setValue] = React.useState([20, 37]);
    const text = props.text

    const value = props.value
    const setValue = props.setValue
    const commit = props.commit

    const min = props.min
    const max = props.max
    const step = props.step

    const handleChange = (event, newValue) => {
        //debugger
        setValue(newValue);
    };

    return (
        <div className="rangeDiv">
            <ThemeProvider theme={MuiTheme}>

                <Box>
                    <Slider
                        value={value}
                        onChange={handleChange}

                        getAriaValueText={valuetext}
                        min={min}
                        max={max}
                        step={step}
                        onChangeCommitted={commit}
                    />
                    <p>{text}</p>
                </Box>
            </ThemeProvider>
        </div>
    );


}

export default Range