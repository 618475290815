const APICall = (method, url, data) => {

    //console.log(sessionStorage.getItem("apiKEY"))
    //debugger

    fetch(url, {
        method: method,
        headers: {
            "content-type": "application/json",
            "x-api-key": sessionStorage.getItem("apiKEY"),
            "Accept": '*/*',
        },
        body: JSON.stringify(data)
    }).then(r => {
        console.log(method + ' ' + url)
        //console.log(r)
        return r.json()
    }).then(data => {
        return data
    }).catch(err => {
        //debugger
        if (err.name === 'AbortError') { // abychom zase nemenili state pokud uz jsme odesli ze stanky
            console.log(err.message)
        }
        else {
            console.log(err.message)
        }
    })

}

export default APICall