import "../css/Home.css"
import config from "../config/config.json";
import {useEffect, useState} from "react";
import ContentBox from "../components/ContentBox";
import Hww from "../components/Hww";
import TaC from "../components/TaC";
import Events from "../components/Events";
import {useTranslation, Trans} from "react-i18next";

const Home = (props) => {

    const { t } = useTranslation();

    const refresh = props.refresh
    const setCustomCaption = props.setCustomCaption
    const setCustomAction = props.setCustomAction
    const menuSelect = props.menuSelect

    const [selectedHomeContent, setSelectedHomeContent] = useState("home")
    const [infoURL, setInfoURL] = useState("")

    const [position, setPosition] = useState("")


    useEffect(() => {
        selectContent("home")
    }, [refresh])


    const selectContent = (content) => {
        console.log(content)
        switch (content) {
            case "hww":
                setSelectedHomeContent("hww")
                setCustomCaption("How we works")
                break
            case "t&c":
                setSelectedHomeContent("tac")
                setCustomCaption("Terms & Conditions")
                break
            case "events":
                setSelectedHomeContent("events")
                setCustomCaption("Upcoming events")
                break
            case "messages":
                menuSelect(3)
            default:
                setSelectedHomeContent("home")
        }
    }

    const setCoords = (position) => {
        console.log("set position")
        console.log(position.coords.latitude + ' - ' + position.coords.longitude)
        setPosition(position.coords.latitude + ' - ' + position.coords.longitude)
    }

    navigator.geolocation.getCurrentPosition(setCoords)



    return (

        <>
            {(selectedHomeContent === "info") && <>
                <ContentBox url={infoURL} />
            </>}

            {(selectedHomeContent === "hww") && <>
                <Hww />
            </>}

            {(selectedHomeContent === "tac") && <>
                <TaC />
            </>}

            {(selectedHomeContent === "events") && <>
                <Events />
            </>}

            {(selectedHomeContent === "home") &&
                <div className="home">

                    <span className="home-logo-small">
                        <img src={config.IMG_URL + "logo_small2.png"} alt="logo"/>
                    </span>

                    <span className="home-logo">
                        <img src={config.IMG_URL + "logo2.png"} alt="logo"/>
                    </span>

                    <span className="home-info">
                            {t('members-welcome')}
                        </span>

                    <div className="home-boxes">

                        <div className="home-box-withoutnumbers home-box-grey" onClick={() => selectContent("hww")}>

                            <span className="home-box-number"></span>
                            <span className="home-box-text">{t('how we works')}</span>

                        </div>

                        <div className="home-box-withoutnumbers home-box-grey" onClick={() => selectContent("t&c")}>

                            <span className="home-box-number"></span>
                            <span className="home-box-text">{t('terms & conditions')}</span>

                        </div>

                        <div className="home-box" onClick={() => selectContent("events")}>

                            <span className="home-box-number">2</span>
                            <span className="home-box-text">{t('events near you')}</span>

                        </div>

                        {position}


                    </div>


                </div>
            }

        </>

    )
}

export default Home