import "../css/TxtBox.css"
import TextField from '@mui/material/TextField';
import {ThemeProvider} from "@mui/material";
import MuiTheme from "../themes/MuiTheme";

const TxtBox =(props) => {

    const label = props.label
    const value = props.value
    const type = props.type
    const handleChange = props.handleChange
    const desc = props.desc
    const multiline = props.multiline


    return (
        <>
            <ThemeProvider theme={MuiTheme}>
                <TextField
                    type={type}
                    label={label}
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                    sx={{
                        width:"100%",
                        color: "white"
                    }}

                    variant="standard"
                    multiline={multiline}
                />
            </ThemeProvider>

            <p>{desc}</p>

        </>
    )
}

export default TxtBox