// import "../css/Profile.css"
import { format } from 'date-fns'
import config from "../config/config.json";
import React, {useState} from "react";

const InvitationInGrid = (props) => {

    const ID = props.id
    const code = props.code
    const created = props.created
    const used = props.used
    const usedBy = props.usedBy
    const note = props.note
    const img = props.img

    console.log(created)

    const [expanded, setExpanded] = useState(false)

    const changeView = () => {
        setExpanded(!expanded)
    }


    return (

        <>

            {!expanded &&
                <>
                    <div className="invitation-collapsed-box">
                        {usedBy === "" && <div className="invitation-collapsed-icon green" onClick={changeView}>
                            <ion-icon name="caret-down-circle-outline"></ion-icon>
                        </div>}
                        {usedBy !== "" && <div className="invitation-collapsed-icon red" onClick={changeView}>
                            <ion-icon name="caret-down-circle-outline"></ion-icon>
                        </div>}
                        <div className="invitation-collapsed-photo" onClick={changeView}>{img !== "" &&
                            <img src={config.UIMG_URL + img} alt=""/>}</div>
                        <div className="invitation-collapsed-code">{code}</div>

                    </div>
                    <div className="invitation-line"></div>
                </>
            }

            {expanded && <>
                <div className="invitation-in-grid">


                    <div className="invitation-box " onClick={changeView}>
                        <div className="invitation-photo">
                            {img !== "" && <img src={config.UIMG_URL + img} alt=""/>}
                        </div>
                    </div>

                    <div className="invitation-box">
                        <p className="code">{code}</p>
                        <p className="small bold">{created}</p>
                        <p className="small">{note}</p>
                        {usedBy === "" && <p className="small green">free to use</p>}
                        {usedBy !== "" && <p className="small red">accepted by {usedBy}</p>}
                    </div>

                </div>

                <div className="invitation-line"></div>
            </>}

        </>
    )
}

export default InvitationInGrid
