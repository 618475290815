// import UserInfo from "./UserInfo";
import React, {useState} from "react";
// import Select from 'react-select';
import config from "../config/config.json";
import {Button, ThemeProvider} from "@mui/material";
import MuiTheme from "../themes/MuiTheme";
import TxtBox from "../components/TxtBox";
import UserInfo from "./UserInfo";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";

const Reg = (props) => {

    const { t } = useTranslation();

    const code = props.code
    const setUserCreated = props.setUserCreated
    let setUserEmail = props.setEmail
    let setPassword = props.setPassword


    let regData = {
        code: code,
        typeID: "",
        name: "",
        nick: "",
        birthDate: "",
        email: "",
        phone: "",
        country: "",
        city: ""
    };

    const [name, setName] = useState("")
    const [nick, setNick] = useState("")
    const [birthDate, setBirthDate] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [country, setCountry] = useState("CZ")
    const [city, setCity] = useState("")

    const [err, setErr] = useState("")
    const [isPending, setIsPending] = useState(false)

    function handleSubmit() {

        regData.typeID = "1"
        regData.name = name
        regData.nick = nick
        regData.birthDate = birthDate
        regData.email = email
        regData.phone = phone
        regData.country = country
        regData.city = city

        setIsPending(true)
        setErr(null)

        //debugger
        fetch( config.API_URL + 'user/', {
            method: 'POST',
            headers: {"content-type": "application/json"},
            body: JSON.stringify(regData)
        }).then(r => {
            console.log('ted')
            console.log(r)
            return r.json()
        }).then(data => {
            //debugger
            console.log(data.Err)
            if (data.err === undefined) {
                setUserEmail(data.Email)
                setPassword(data.Password)
                setUserCreated(true)
                setIsPending(false)
            }
            else {
                setIsPending(false)
                setErr(data.err)
            }
        })
    }

    return(
        <>

            <div className="register-space" />
            <div className="register-header">
                <img src={config.IMG_URL + "logo2.png"} className="register-header-logo" alt="logo"/>
            </div>

            <div className="register-code-page">

                <ThemeProvider theme={MuiTheme}>

                    <p>{t('reg congrats')}</p>
                    <h3>{t('invited by user')}</h3>
                    <UserInfo invitationCode={code} />
                    <h3>{t('create account')}</h3><div className="register-space" />


                        <div className="register-code-page-formDiv">

                            {/*TODO - dodelat validaci*/}

                                <TxtBox label={t('account type')} value="W2 - woman gold" desc=""/><div className="register-space"/>
                                <TxtBox label={t('name')} value={name} handleChange={setName} desc=""/><div className="register-space"/>
                                <TxtBox label={t('nick')} value={nick} handleChange={setNick} desc=""/><div className="register-space"/>
                                <TxtBox label={t('birth date')} value={birthDate} handleChange={setBirthDate} desc=""/><div className="register-space"/>
                                <TxtBox label={t('email')} value={email} handleChange={setEmail} desc=""/><div className="register-space"/>
                                <TxtBox label={t('phone')} value={phone} handleChange={setPhone} desc=""/><div className="register-space"/>
                                <TxtBox label={t('country')} value={country} handleChange={setCountry} desc=""/><div className="register-space"/>
                                <TxtBox label={t('city')} value={city} handleChange={setCity} desc=""/><div className="register-space"/>


                                <div className="formText">{t('initial password sent')}</div>


                        </div>
                        <div className="register-space"/>

                        {!isPending &&
                            <div className="center">
                                <Button variant="outlined" onClick={handleSubmit}>{t('register')}</Button>
                            </div>
                        }
                        {isPending && <div className="register-importantInfo">{t('wait')} </div>}

                        {err && <div className="register-wrong-code">{err}</div>}

                </ThemeProvider>


            </div>

        </>

    )
}

export default Reg
