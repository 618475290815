import "../css/Profile.css"
import config from "../config/config.json";
import React, {useEffect, useState} from "react";
import TxtBox from "../components/TxtBox";
import {Button, ThemeProvider} from "@mui/material";
import MuiTheme from "../themes/MuiTheme";
import APICall from "../tools/APICall";
import useFetch from "../tools/UseFetch";
import Events from "../components/Events";
import Invitations from "../components/Invitations";
import Waiting from "../components/Waiting";

const Profile = (props) => {

    const refresh = props.refresh
    const menuSelect = props.menuSelect

    const [bodySave, setBodySave] = useState(false)
    const [infoSave, setInfoSave] = useState(false)

    const [height, setHeight] = useState("")
    const [weight, setIWeight] = useState("")
    const [bodyType, setBodyType] = useState("")
    const [breasts, setBreasts] = useState("")
    const [hair, setHair] = useState("")

    const [profileText, setProfileText] = useState("")
    const [lookingFor, setLookingFor] = useState("")

    const [refreshNum, setRefreshNum] = useState(Date.now)

    const { data:userinfo, isPendingui, errorui} = useFetch(config.API_URL + 'settings/get-own-userinfo', refresh)
    const { data:userPhoto, isPendingPhoto, errorPhoto} = useFetch(config.API_URL + 'user/get-own-profilePhoto', refresh)

    const [profilePhoto, setProfilePhoto] = useState(null)
    const [userHasPhoto, setUserHasPhoto] = useState(0)

    const [selectedProfileContent, setSelectedProfileContent] = useState("home")

    useEffect(() => {
        if(userinfo != null) {
            setHeight(userinfo.Height)
            setIWeight(userinfo.Weight)
            setBodyType(userinfo.BodyType)
            setBreasts(userinfo.Breasts)
            setHair(userinfo.Hair)

            setProfileText(userinfo.ProfileText)
            setLookingFor(userinfo.LookingFor)
        }
    }, [userinfo])

    useEffect(() => {
        if(userPhoto != null) {
            if (userPhoto.length > 0) {
                setProfilePhoto(userPhoto[0].Name)
                setUserHasPhoto(1)
            }
            else {
                setUserHasPhoto(-1)
            }
        }
    }, [userPhoto])


    useEffect(() => {
        if(userinfo != null) {
            let showSave = (height !== userinfo.Height || weight !== userinfo.Weight || bodyType !== userinfo.BodyType || breasts !== userinfo.Breasts || hair !== userinfo.Hair)
            setBodySave(showSave)
        }
    }, [bodyType, breasts, hair, height, weight, refreshNum])


    const updateBodyData = () => {
        console.log("Update Contact now")

        userinfo.Height = height
        userinfo.Weight = weight
        userinfo.BodyType = bodyType
        userinfo.Breasts = breasts
        userinfo.Hair = hair

        APICall('POST', config.API_URL + 'user/update-own-bodyinfo', userinfo)

        setRefreshNum(Date.now)
    }


    useEffect(() => {
        if(userinfo != null) {
            let showSave = (profileText !== userinfo.ProfileText || lookingFor !== userinfo.LookingFor)
            setInfoSave(showSave)
        }
    }, [lookingFor, profileText, refreshNum])

    useEffect(() => {
        selectContent("home")
    }, [refresh])


    const updateInfoData = () => {
        console.log("Update Contact now")

        userinfo.ProfileText = profileText
        userinfo.LookingFor = lookingFor

        APICall('POST', config.API_URL + 'user/update-own-profileinfo', userinfo)

        setRefreshNum(Date.now)
    }

    const selectContent = (content) => {
        console.log(content)
        switch (content) {
            case "messages":
                menuSelect(3)
                break
            case "invitations":
                setSelectedProfileContent("invitations")
                break
            default:
                setSelectedProfileContent("home")
        }
    }

    return (
        <>

            {userHasPhoto === 0 && <>

                <div className="profile">
                    <div className="profilePhotoWait">
                        <p>loading photo...</p>
                    </div>
                </div>

            </>}

            {userHasPhoto !== 0 &&

            <div className="profile">

                {userHasPhoto === 1 && <>

                    <div className="profilePhoto">
                        <img src={config.UIMG_URL + profilePhoto} alt=""/>

                    </div>

                    <div className="profilePhotoOverlay" onClick={() => menuSelect(4)}><p>Click to view photos</p></div>

                </>}

                {userHasPhoto ===-1 && <div className="profileNoPhoto">
                    <span className="profileNoPhoto-text">
                        No profile photo yet <br/>
                        select one in <span className="link" onClick={() => menuSelect(4)}>photos</span>
                    </span>
                </div>}

            </div>}

            {(selectedProfileContent === "invitations") && <>
                <Invitations refres={refresh} />
            </>}

            {(selectedProfileContent === "home") &&
                <div className="settings">

                <>

                    <div className="home-boxes">
                        <div className="home-box" onClick={() => selectContent("messages")}>

                            <span className="home-box-number">11</span>
                            <span className="home-box-text">New messages</span>

                        </div>

                        <div className="home-box home-box-grey">

                            <span className="home-box-number grey">48</span>
                            <span className="home-box-text">New visits</span>

                        </div>

                        <div className="home-box" onClick={() => selectContent("invitations")}>

                            <span className="home-box-number">5</span>
                            <span className="home-box-text">Accepted invitations</span>

                        </div>

                    </div>

                    <h2>Something about you</h2>
                    <p className="settingsText">Tell us something about you ... make yourself more attractive</p>

                    {(config.SHOW_PROFILE_INFO === "true") && <div className="section">

                        <h2>Who are you?</h2>

                        <p className="sectionText">
                            Describe yourself... Write whatever you want, it's up to you.
                            And also - tell us what do you want (eg. relationship, friend with benefits, ...).
                            It may be important
                        </p>

                        <TxtBox label="About you" value={profileText} handleChange={setProfileText} multiline={true}
                                desc=""/>
                        <TxtBox label="What are you looking for" value={lookingFor} handleChange={setLookingFor}
                                desc=""/>

                        {infoSave && <span className="settings-section-save">
                            <ThemeProvider theme={MuiTheme}>
                                <Button variant="outlined" onClick={updateInfoData}>Save changes</Button>
                            </ThemeProvider>
                        </span>}

                    </div>}

                    {(config.SHOW_BODY_INFO === "true") && <div className="section">

                        <h2>Your body</h2>

                        <p className="sectionText">
                            No exact format needed - just enter some description for each field.
                            Whatever you want
                        </p>

                        <TxtBox label="height" value={height} handleChange={setHeight} desc=""/>
                        <TxtBox label="weight" value={weight} handleChange={setIWeight} desc=""/>
                        <TxtBox label="body type" value={bodyType} handleChange={setBodyType} desc=""/>
                        <TxtBox label="breasts" value={breasts} handleChange={setBreasts} desc=""/>
                        <TxtBox label="hair" value={hair} handleChange={setHair} desc=""/>

                        {bodySave && <span className="settings-section-save">
                            <ThemeProvider theme={MuiTheme}>
                                <Button variant="outlined" onClick={updateBodyData}>Save changes</Button>
                            </ThemeProvider>
                        </span>}

                    </div>}


                    <p className="settingsText">
                        Wanna tweak your profile more? Go to <span className="link"
                                                                   onClick={() => menuSelect(5)}>settings</span>
                    </p>


                </>

            </div>}

        </>
    )
}

export default Profile