import "../css/Profile.css"
import useFetch from "../tools/UseFetch";
import config from "../config/config.json";
import Waiting from "./Waiting";
import React, {useState} from "react";
import PhotoInGrid from "./PhotoInGrid";
import InvitationInGrid from "./InvitationInGrid";
import {Button, ThemeProvider} from "@mui/material";
import MuiTheme from "../themes/MuiTheme";
import TxtBox from "./TxtBox";
import APICall from "../tools/APICall";

const Invitations = (props) => {

    const refresh = props.refresh
    // const setCustomCaption = props.setCustomCaption
    // const setCustomAction = props.setCustomAction
    // const menuSelect = props.menuSelect

    const [refreshNum, setRefreshNum] = useState(Date.now)

    const { data:invitations, isPending, error} = useFetch(config.API_URL + 'invitations/get-own', refreshNum)
    const [showNewInvitation, setShowNewInvitation] = useState(false)
    const [newInvitationNote, setNewInvitationNote] = useState("")
    const [cpErr, setCPErr] = useState("")

    const CreateInvitation = () => {
        console.log("Create invitation now")

        const postdata = {
            "Note": newInvitationNote
        }
        //debugger
        //APICall('POST', config.API_URL + 'invitations/create', postdata)

        fetch(config.API_URL + 'invitations/create', {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "x-api-key": sessionStorage.getItem("apiKEY"),
                "Accept": '*/*',
            },
            body: JSON.stringify(postdata)
        }).then(r => {
            //console.log(method + ' ' + url)
            console.log(r)
            setRefreshNum(Date.now)
            //refresh()
        }).then(data => {
            console.log(data)
        }).catch(err => {
            //debugger
            if (err.name === 'AbortError') { // abychom zase nemenili state pokud uz jsme odesli ze stanky
                console.log(err.message)
            }
            else {
                console.log(err.message)
            }
        })

        setNewInvitationNote("")
        setShowNewInvitation(false)
        //TODO: Na mobilu se nekdy refresne a nekdy ne :(
        setRefreshNum(Date.now)
    }

    const ShowNewInvitation = () => {
        console.log("Chnage pass")
        //setCustomCaption("Change pass")
        setShowNewInvitation(true)
    }

    const HideNewInvitation = () => {
        setShowNewInvitation(false)
        // setOldPass("")
        // setNewPass("")
        // setNewPassAgain("")
        // setCPIsPending(false)
        setNewInvitationNote("")
        setCPErr("")
    }

    return (
        <>

            <div className="invitations-outer">

                {isPending && <Waiting />}
                {error && <div className="loginError">{error}</div>}

                    <div className="invitations-page">
                        <h2>Your Invitations</h2>
                        <p>
                            By invitations you can invite other users into Diamond Animals family (DA).
                            Only woman can be invited.
                            And because DA is for success and beauty invite beautiful woman only.
                            Now it's up to you - we trust you.
                        </p>


                        <div className="invitations-section">
                            <h4>Create new invitation</h4>
                            {!showNewInvitation && <>
                                <p className="invitations-sectionText">
                                    Do you know attractive woman who want to join us? Feel free to invite her
                                </p>
                                <ThemeProvider theme={MuiTheme}>
                                    <Button variant="outlined" onClick={ShowNewInvitation}>Create invitation</Button>
                                </ThemeProvider>
                            </>}

                            {showNewInvitation && <>

                                <p className="invitations-sectionText">
                                    Enter note for your future reference. It will be visible only to you
                                </p>

                                <TxtBox type="text" label="Note" value={newInvitationNote} handleChange={setNewInvitationNote} desc=""/>

                                <ThemeProvider theme={MuiTheme}>
                                    <Button variant="outlined" onClick={CreateInvitation}>Create</Button>&nbsp;
                                    <Button variant="outlined" onClick={HideNewInvitation}>Cancel</Button>
                                </ThemeProvider>

                                {cpErr && <p className="error">{cpErr}</p>}

                            </>}

                        </div>


                        {!isPending && invitations && (invitations.length !== 0) && invitations.map(invitation => (
                            <InvitationInGrid key={invitation.ID}
                                              id={invitation.ID}
                                              code = {invitation.Code}
                                              note = {invitation.Note}
                                              created = {invitation.Created}
                                              used = {invitation.Used}
                                              usedBy = {invitation.UsedBy}
                                              img = {invitation.UsedByImage}
                            />
                        ))}

                    </div>
            </div>

        </>
    )
}

export default Invitations