import {useEffect, useState} from "react";

const useFetch = (url, refreshNum) => {

    const [data, setData] = useState(null)
    const [isPending, setIsPending] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {

        // Kvuli preruseni pokud odejdeme ze stranky drive, nez se dokonci fetch
        const abortCont = new AbortController(); //TODO tohle funguje nejak divne - vzdy to vyvola to presueni. Podivat se na to proc ...

        //debugger

        console.log('effect ran ')
        fetch(url, {
            headers: {
                'x-api-key': sessionStorage.getItem("apiKEY"),
                'User-Agent' : 'My-App',
                'Accept': '*/*',
            },
            signal: abortCont.signal
        })
            .then(res => {
                //debugger
                if (!res.ok){
                    throw Error('Could not retrieve data for this resource - ' + res.status + ' ' + res.statusText)
                }
                return res.json()
            }).then(data => {
            setData(data)
            setIsPending(false)
            setError(null)
        }).catch(err => {
            //debugger
            if (err.name === 'AbortError') { // abychom zase nemenili state pokud uz jsme odesli ze stanky
                console.log(err.message)
            }
            else {
                console.log(err.message)
                setError(err.message)
                setIsPending(false)
            }
        })

        return () => abortCont.abort()

    }, [url, refreshNum]);

    return { data, isPending, error }

}

export default useFetch;
