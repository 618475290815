import config from "../config/config.json";
import React from "react";

const UserCreated = (props) => {

    const userEmail = props.userEmail
    const userPassword = props.userPassword

    return(

        <>

            <div className="register-space"></div>
            <div className="register-header">
                <img src={config.IMG_URL + "logo2.png"} className="register-header-logo" alt="logo"/>
            </div>

            <div className="register-code-page">
                <h2>Welcome!</h2>
                <p>Welcome to diamond animals family. Now you are one of us. Your password {userPassword} has been sent
                    to {userEmail}
                </p>
                <div className="register-space"></div>
                <p>
                    First write down your username (<span>{userEmail}</span>) and password (<span>{userPassword}</span>)
                    and then go to <a href={config.MEMBERS_APP_URL}>{config.MEMBERS_APP}</a> and log in.
                </p>
                <h3>see you there ...</h3>
            </div>
        </>
    )
}

export default UserCreated